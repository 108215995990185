import React, {useEffect} from 'react';
import baseUrl from '@root/config';

function Backend() {
    useEffect(() => {
        window.location.replace(`${baseUrl}/admin`);
    }, []);    

    return <></>;
}


export default Backend;
