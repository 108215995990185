import axios from 'axios';
import baseUrl from '@root/config';

export const getQuery = (url, parameters='populate=*') => axios.get(baseUrl + url + '?' + parameters ).then(res => res.data);

export const postQuery = (url, arr, wrapped=true) => Promise.all(arr.map(element => axios.post(baseUrl + url, (wrapped) ? { data: element } : element )));

export const putQuery = (url, arr, wrapped=true) => Promise.all(arr.map(element => axios.put(baseUrl + url + `/${element.id}`, (wrapped) ? { data: element } : element)));

export const deleteQuery = (url, arr) => Promise.all(arr.map(id => axios.delete(baseUrl + url + `/${id}`)));

