import React, {useState, useEffect} from 'react';
import { useQuery } from 'react-query';
import logo from '@assets/logo.png';
import { fetchParties } from "@queries/Parties";

const Auth = ({
    children = null
}) => {    
    const [partiesAll, setPartiesAll] = useState([]);
    const [equipe, setEquipe] = useState('');

    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    // Fetching data
    const partiesQuery = useQuery('parties', fetchParties, { 
        retry: false,
        onSuccess: (data) => setPartiesAll(data?.data),
        refetchInterval: 5000
    });    

    // Authenticating
    const authenticate = () => {
        const storedEquipe = localStorage.getItem('team');
        const index = partiesAll?.filter(o => o?.Statut !== 'Finie')?.flatMap(o => [o?.Equipe_1?.trim(), o?.Equipe_2?.trim()] )?.filter(o => o != null).indexOf(equipe?.trim() || storedEquipe?.trim())

        if (index > -1) {
            setIsError(false)

            localStorage.setItem('team',  partiesAll?.filter(o => o?.Statut !== 'Finie')?.flatMap(o => [o?.Equipe_1, o?.Equipe_2] )?.filter(o => o != null)[index])
            localStorage.setItem('partie', partiesAll?.filter(o => o?.Statut !== 'Finie')?.filter(o => o?.Equipe_1?.trim() === (equipe?.trim() || storedEquipe?.trim()) || o?.Equipe_2?.trim() === (equipe?.trim() || storedEquipe?.trim()))?.at(0)?.id);
            setIsSuccess(true)
        }
        else if ( (equipe || storedEquipe) == null ){
            localStorage.removeItem('team');
            localStorage.removeItem('partie');
        }
        else {
            setIsError(!!(equipe || storedEquipe))
            localStorage.removeItem('team');
            localStorage.removeItem('partie');
        }
    }

    // Authentication based on localStorage
    useEffect(() => {
        if(partiesQuery.isSuccess)
            authenticate();
    }, [partiesQuery.isSuccess]);

    return ( partiesQuery.isLoading ) ? (
        <div className="container">
            <div className="d-flex justify-content-center align-items-center"  style ={{ height:'100vh' }}  >
                <div className="spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="mt-3 ml-2">
                    <p> Chargement en cours ...</p>
                </div>
            </div>
        </div>
    ) : (isSuccess) ?  (
        children
    ) : 
    (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                        <div className="card my-3">
                            <div className="card-body p-5 text-center">
                                <img className="mb-3" width='200' src={logo} />
                                <div className="h3 font-weight-light mb-0 mt-2">Escape Game <br/>Prêt pour l'aventure</div>
                            </div>
                            <hr className="my-0" />
                            <div className="card-body p-5">
                                <form>
                                    { ( isError ) &&

                                        <div className="alert alert-danger" role="alert">
                                            <strong>Erreur !</strong> l'équipe que vous souhaitez rejoindre n'existe pas
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="text-gray-600 small" htmlFor="equipe">Equipe / Team</label>
                                        <input className="form-control form-control-solid py-4" type="text" placeholder="" aria-label="equipe" aria-describedby="equipe" name="equipe" required="required" onChange={e => setEquipe(e.target.value)} />
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-between mb-0">
                                        <div className="custom-control custom-control-solid custom-checkbox"></div>
                                        <button className="btn btn-outline-primary mt-4" onClick={e => { e.preventDefault(); authenticate() }}>Go</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

const styles = {
    by: {
      color: 'rgba(0,0,0,0.5)',
      fontWeight: 500
    },
    brand: {
      fontSize: 15,
      fontWeight: 400
    }
  }

export default Auth;