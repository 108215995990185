import React, { useEffect, useState } from 'react';
import { sendNotification  } from '@queries/Notifications';

export const useGeolocation = (target) => {
  const [hasReached, setHasReached] = useState(false);
  const [haveSentNotification, setHaveSentNotification] = useState(false);

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    Number.prototype.toRad = function() {
        return this * Math.PI / 180;
    }
    
    var R = 6371; // km 
    //has a problem with the .toRad() method below.
    var x1 = lat2-lat1;
    var dLat = x1.toRad();  
    var x2 = lon2-lon1;
    var dLon = x2.toRad();  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
            Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);  
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; 

    return d;
  }

  const success = (pos) => {
    var crd = pos.coords;

    console.log(haversineDistance(target.latitude, target.longitude, crd.latitude, crd.longitude) * 1000)
  
    if (haversineDistance(target.latitude, target.longitude, crd.latitude, crd.longitude) * 1000 < 45) {
      console.log('Congratulations, you reached the target');
      setHasReached(true);
    }
  }
  
  const error = (err) => {
    console.warn('ERROR(' + err.code + '): ' + err.message);
  }
  
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0
  };
  
  useEffect(() => {
    let id = null;
    id = navigator.geolocation.watchPosition(success, error, options); 

    return () => {
      navigator.geolocation.clearWatch(id);
    }
  });

  useEffect(() => {
    if (hasReached && !haveSentNotification) {
      sendNotification('Bravo vous avez atteint la prochaine étape ! Consultez la prochaine énigme')
        .then(data => console.log(data))
        .catch(e => console.log(e));

      setHaveSentNotification(true);
    }
  }, [hasReached, haveSentNotification]);

  return hasReached;
};

export default useGeolocation;