import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { fetchParties } from '@queries/Parties';
import { useQuery } from 'react-query';
import {text_language_dict} from '@root/config';


function Done() {
    const [enigme, setEnigme ] = useState([]);

    const teamName = localStorage.getItem('team');
    const partieId = parseInt(localStorage.getItem('partie'));
    const [partie, setPartie] = useState({})
    const { state: enigmeId } = useLocation();
    const history = useHistory();

    // Fetch Data
    useQuery('parties', fetchParties, {
        onSuccess: (data) => {
            const partieLocal = data?.data?.filter(o => o?.id === partieId)?.at(0);
            setPartie(partieLocal)
            const numeroEquipe = ([partieLocal?.Equipe_1, partieLocal?.Equipe_2])?.indexOf(teamName) + 1;

            setEnigme(partieLocal?.Scenario?.scenario?.data[`Enigmes_Equipe_${numeroEquipe}`]?.filter(o => o?.enigme?.data?.id === enigmeId)?.at(0)?.enigme?.data);
        }
    })

    return(
    (!partie || Object.keys(partie)?.length === 0) ? 
        (<div className="container">
        <div className="d-flex justify-content-center align-items-center"  style ={{ height:'100vh' }}  >
            <div className="spinner-border " role="status">
            </div>
        </div>
    </div>) : 
        (<div className="container h-100 d-flex flex-column justify-content-between">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                    <div className="card my-3">
                        <div className="card-body p-3">
                            <h4 className='mt-2 font-weight-bold'>{enigme?.Titre}</h4>
                            <div className='bg-gray-200 p-2 mt-4 d-flex flex-column'>
                                💡 {text_language_dict[partie?.Scenario?.scenario?.data.Langue]?.fun_fact} 
                                { enigme?.Le_saviez_vous_image?.data?.url && (
                                    <img className='my-4' style={styles.image} src={enigme?.Le_saviez_vous_image?.data?.url} />
                                )}
                                { enigme?.Le_saviez_vous }
                            </div>
                            <div className='mt-4 d-flex flex-column align-items-center'>
                                {(/^[ ]*$/.test(enigme?.Map_link) || (enigme?.Map_link === undefined) || enigme?.Map_link === null) ? <h4 className='mb-0 font-weight-bolder'>{ enigme?.Prochaine_enigme }</h4> : <a href={enigme?.Map_link} className='link-primary mt-4 mb-4'> {text_language_dict[partie?.Scenario?.scenario?.data.Langue]?.find_brice_de_nice}  </a>}
                                <Link to='/' className='link-primary mt-4 mb-4' >{text_language_dict[partie?.Scenario?.scenario?.data.Langue]?.back_to_main} </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <small className='d-block m-0 text-center pb-2'>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.copyright}</small>
        </div>)
    )
}

const styles = {
    image: {
        maxWidth: '100%',
        maxHeight: '300px'
    }
}

export default Done;
