import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { fetchParties, updateParties } from '@queries/Parties';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import moment from 'moment';

import './button.css' 

import { text_language_dict } from '@root/config';

function Enigme() {
    const [partie, setPartie] = useState({});
    const [numeroEquipe, setNumeroLocal] = useState();
    const [enigme, setEnigme ] = useState([]);
    const [reponse, setReponse ] = useState();
    const [tentatives, setTentatives] = useState(0);
    const [isError, setIsError] = useState(false);
    const [shake, setShake] = useState(false);

    const teamName = localStorage.getItem('team');
    const partieId = parseInt(localStorage.getItem('partie'));

    const { state: enigmeId } = useLocation();
    const history = useHistory();
    const queryClient = useQueryClient();

    // Fetch Data
    useQuery('parties', fetchParties, {
        onSuccess: (data) => {
            const partieLocal = data?.data?.filter(o => o?.id === partieId)?.at(0);
            const numeroEquipeLocal = ([partieLocal?.Equipe_1, partieLocal?.Equipe_2])?.indexOf(teamName) + 1;

            setPartie(partieLocal);
            setNumeroLocal(numeroEquipeLocal);
            setEnigme(partieLocal?.Scenario?.scenario?.data[`Enigmes_Equipe_${numeroEquipeLocal}`]?.filter(o => o?.enigme?.data?.id === enigmeId)?.at(0)?.enigme?.data);
        }
    })

    // Modify Data
    const updatePartie = useMutation((parties) => updateParties(parties), {
        onSuccess: () => {
            queryClient.invalidateQueries('parties');
            history.push({
                pathname: '/enigme/done',
                state: enigmeId
            });
        }
    })

    // Check result
    const checkResult = () => {
        if (parseInt(reponse) === parseInt(enigme?.Reponse)) {
            const newId = enigme?.id;

            updatePartie.mutate([{
                id: partie?.id,
                [`Resolue_equipe_${numeroEquipe}`]: [...partie[`Resolue_equipe_${numeroEquipe}`], newId ],
                ...(partie?.Scenario?.scenario?.data[`Enigmes_Equipe_${numeroEquipe}`]?.length === (partie[`Resolue_equipe_${numeroEquipe}`].length + 1)  && { [`Score_Equipe_${numeroEquipe}`]: moment().toISOString() })
            }])
        }
        else {
            setIsError(true);
            setShake(true)
            setTentatives(tentatives + 1);
        }
    }

    // Stop shaking
    useEffect(() => {
        if (shake)
            setTimeout(function() { setShake(false) }, 1000);
    }, [shake])

    return(

    (!partie || Object.keys(partie)?.length === 0) ? 
        (<div className="container">
        <div className="d-flex justify-content-center align-items-center"  style ={{ height:'100vh' }}  >
            <div className="spinner-border " role="status">
            </div>
        </div>
    </div>) : 
        (<div className="container h-100 d-flex flex-column justify-content-between">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                    <div className="card my-3">
                        <div className="card-body p-3">
                            <Link to='/' className='link-primary' >&lt; {text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.back}</Link>
                            <h4 className='mt-4 font-weight-bold'>{enigme?.Titre}</h4>
                            { enigme?.Image?.data?.url && (
                                <img className='mt-4 w-100' src={enigme?.Image?.data?.url} />
                            )}
                            { enigme?.Enonce && (
                                <>
                                    <h4 className='mt-4 font-weight-bold'>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.riddle_description}</h4>
                                    <p>
                                        {enigme?.Enonce}
                                    </p>
                                </>
                            )}

                            <h4 className='mt-4 font-weight-bold'>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.answer}</h4>
                            <input className="form-control form-control-solid py-4" type="number" placeholder={text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.answer} aria-label="reponse" aria-describedby="reponse" name="reponse" onChange={(e) => setReponse(e.target.value) }/>
                            { isError && (
                                <small className={'form-text text-danger ' + ((shake) ? 'shake' : '')} style={{ width: 'fit-content'}}>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.wrong_answer}</small>
                            )}
                            { enigme?.Indices?.sort((a,b) => a?.Tentative < b?.Tentative)?.filter(o => o?.Tentative <= tentatives)?.map(o => (
                              <small>💡 {o?.Indice}</small>  
                            ))}
                            <div className='mt-4 d-flex justify-content-end'>
                                <button className='btn btn-outline-dark' onClick={() => checkResult()}>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.validate}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <small className='d-block m-0 text-center pb-2'>{text_language_dict[partie?.Scenario?.scenario?.data?.Langue]?.copyright}</small>
        </div>)
    )
}


export default Enigme;
