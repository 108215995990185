import * as queries from '@queries/utils';
import axios from 'axios';
import baseUrl from '@root/config';

export const fetchParties = () => queries.getQuery('/api/parties', 'populate=Scenario.scenario.Enigmes_Equipe_1.enigme.Indices,Scenario.scenario.Enigmes_Equipe_2.enigme.Indices,Scenario.scenario.Enigmes_Equipe_1.enigme.Image,Scenario.scenario.Enigmes_Equipe_2.enigme.Image,Scenario.scenario.Enigmes_Equipe_1.enigme.Le_saviez_vous_image,Scenario.scenario.Enigmes_Equipe_2.enigme.Le_saviez_vous_image');

export const createParties = (parties) => queries.postQuery('/api/parties', parties);

export const updateParties = (parties) => queries.putQuery(`/api/parties`, parties);

export const deleteParties = (partiesToDelete) => queries.deleteQuery(`/api/parties`, partiesToDelete);

export const askForHelp = (id) => axios.get(`${baseUrl}/api/parties/alarm/${id}`);