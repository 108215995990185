import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Auth from '@components/Auth';
import { Main, Enigme, Done, Backend } from '@pages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route exact path='/admin'>
              <Backend />
            </Route>
            <Auth>
              <Route exact path='/'>
                <Main />
              </Route>
              <Route exact path='/enigme'>
                <Enigme />
              </Route>
              <Route exact path='/enigme/done'>
                <Done />
              </Route>
            </Auth>
          </Switch>
        </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
