export default ({
    DEV: 'http://localhost:5100',
    STAGING: 'https://nissalentours-staging-back.herokuapp.com',
	  PRODUCTION: 'https://nissalentours-prod-back.herokuapp.com'
  })[process.env.REACT_APP_ENV || 'DEV'];

export const text_language_dict = {
    "Français" : {
        "timer" : "Temps restant :",
        "message_pre_partie_1" : "La prochaine partie commence le :",
        "date_format" : "DD/MM/YYYY",
        "message_pre_partie_2" : " à ",
        "message_urgence" : "En cas d'urgence", 
        "back" : "Retour", 
        "riddle_description" : "Enoncé", 
        "answer" : "Réponse", 
        "validate" : "Valider", 
        "wrong_answer" : "Ce n'est pas la bonne réponse, rééssayez ",
        "fun_fact" : "Le saviez-vous ?", 
        "find_brice_de_nice" : "Découvrir où se trouve Brice de Nice", 
        "back_to_main" : "Retourner à l'écran principal",
        "copyright": "© Tous droits réservés",
        "is_to_come": "La partie va commencer ...",
        "message_unlock": "J'y suis",
        "message_unlock_alert": "Le maitre du jeu va vous débloquer, rafraichissez d'ici 10 secs.",
        "message_unlock_alert_error": "Une erreur s'est produite, assurez vous d'avoir du réseau."
  },
    "Anglais" : {
        "timer" : "Time remaining :",
        "message_pre_partie_1" : "Next game starts on :",
        "date_format" : "MM/DD/YYYY",
        "message_pre_partie_2" : " at ",
        "message_urgence" : "In case of emergency",
        "back" : "Back", 
        "riddle_description" : "Problem", 
        "answer" : "Answer", 
        "validate" : "Enter", 
        "wrong_answer" : "This is not the right answer, try again",
        "fun_fact" : "Fun fact :", 
        "find_brice_de_nice" : "Find out where Brice de Nice is", 
        "back_to_main" : "Back to Main Page",
        "copyright": "© All rights reserved",
        "is_to_come": "The game is going to start ...",
        "message_unlock": "I m here",
        "message_unlock_alert": "The game master is going to unblock you, refresh the page within 10 secs",
       " message_unlock_alert_error": "An error occured, make sure to have some network."
  }
}
