import React, { useState } from 'react';
import baseUrl from '@root/config';
import axios from 'axios';

export const useNotification = () => {
    const [hasRegistered, setHasRegistered] = useState(false);


    function urlBase64ToUint8Array(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
        
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    navigator.serviceWorker.ready
    .then(function(registration) {
      // Use the PushManager to get the user's subscription to the push service.
      return registration.pushManager.getSubscription()
      .then(async function(subscription) {
        // If a subscription was found, return it.
        if (subscription) {
          return subscription;
        }

        // Get the server's public key
        const response = await axios.get(`${baseUrl}/notifications/vapidPublicKey`);
        const vapidPublicKey = response.data;
        // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
        // urlBase64ToUint8Array() is defined in /tools.js
        console.log('Vapidkey : ' + vapidPublicKey.toString())
        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    
        // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
        // send notifications that don't have a visible effect for the user).
        const result = registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        });

        return result;
      });
    }).then(function(subscription) {
        if (localStorage.getItem('team') && !hasRegistered) {
          // Send the subscription details to the server using the Fetch API.
          axios.post(`${baseUrl}/notifications/register`, {
              key: localStorage.getItem('team'),
              subscription: subscription
          },{
            headers: {
                'Content-type': 'application/json'
            }
          })
          .then(() => setHasRegistered(true))
          .catch((error) => console.log(error));

        }
    })
};

export default useNotification;