import axios from 'axios';
import baseUrl from '@root/config';

export const sendNotification = (message) => axios(`${baseUrl}/notifications/sendNotification`, { 
    method: "POST",
    data: {
        client: localStorage.getItem('team'),
        message
    }
});
