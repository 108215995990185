import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { fetchParties, updateParties, askForHelp } from '@queries/Parties';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useGeolocation from '@hooks/useGeolocation';
import useNotification from '@hooks/useNotification';
import Modal from 'react-bootstrap/Modal';

import {text_language_dict} from "@root/config"

function Main() {
  const [startTime, setStartTime] = useState()
  const [timer, setTimer] = useState('');
  const [partie, setPartie] = useState({});
  const [currentEquipe, setCurrentEquipe] = useState(null);
  const [currentCoordinate, setCurrentCoordinate] = useState({});
  const [language, setLanguage] = useState('')
  const [list_enigmes, setList_enigmes ] = useState([]);

  const [show, setShow] = useState(false);
  const [messageNotification, setMessageNotification] = useState('');

  const teamName = localStorage.getItem('team');
  const partieId = parseInt(localStorage.getItem('partie'));
  const queryClient = useQueryClient();

  const history = useHistory();

  const mutationAskForHelp = useMutation((id) => askForHelp(id), {
    onError: () => setMessageNotification(text_language_dict[language]?.message_unlock_alert_error),
    onSuccess: () => setMessageNotification(text_language_dict[language]?.message_unlock_alert)
  })

  useNotification();

  // Fetch Data
  useQuery('parties', fetchParties, {
    onSuccess: (data) => setPartie(data?.data?.filter(o => o?.id === partieId)?.at(0)),
    refetchInterval: 5000
  })

  // Modify Data
  const updatePartie = useMutation((parties) => updateParties(parties), {
      onSuccess: () => {
          queryClient.invalidateQueries('parties');
      },
      refetchInterval: 5000
  })

  useEffect(() => {
    if (partie)
      setStartTime(moment(partie?.Heure_debut, 'YYYY-MM-DDTHH:mm:ssZ').add(partie?.Scenario?.scenario?.data?.Temps_jeu, 'minutes'))
      setLanguage(partie?.Scenario?.scenario?.data?.Langue)
  }, [partie])
  

  // Handle Timer update
  useEffect(() => {
    if (startTime) {
      let intervalId = setInterval(function() {
        setTimer(Math.max(startTime.diff(moment()), 0))
      }, 1000);


      return () => {
        clearInterval(intervalId)
      }
    }
  }, [startTime]);


  // Handle Enigmes
  useEffect(() => {
    if (partie && Object.keys(partie)?.length > 0) {
      const numeroEquipe = ([partie?.Equipe_1, partie?.Equipe_2])?.indexOf(teamName) + 1;
      const listEnigmes = partie?.Scenario?.scenario?.data[`Enigmes_Equipe_${numeroEquipe}`]?.map(o => o?.enigme?.data);

      setList_enigmes(listEnigmes);
      setCurrentEquipe(numeroEquipe);
    }
    
  }, [partie]);


  // Use Geolocation

  useEffect(() => {
    const nextIndex = partie?.Scenario?.scenario?.data[`Enigmes_Equipe_${currentEquipe}`]?.findIndex(o => o?.enigme?.data?.id === partie[`Etape_equipe_${currentEquipe}`]?.at(-1))
    const nextCoordinate = partie?.Scenario?.scenario?.data[`Enigmes_Equipe_${currentEquipe}`]?.at(nextIndex + 1)?.enigme?.data?.Coordonnees_GPS;

    const [latitude, longitude] = nextCoordinate?.split(',') || [];
    setCurrentCoordinate({
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    })

    
  }, [partie[`Etape_equipe_${currentEquipe}`]?.length]);
  
  // Open Enigme
  const openEnigme = (id) => {
    history.push({
      pathname: (partie[`Resolue_equipe_${currentEquipe}`].includes(id)) ? '/enigme/done' : '/enigme',
      state: id
    });
      
  }

  // Use Geolocation 
  const hasReached = useGeolocation(currentCoordinate);

  useEffect(() => {
    if(hasReached && (partie[`Etape_equipe_${currentEquipe}`]?.length === partie[`Resolue_equipe_${currentEquipe}`]?.length) ) {
      const newId = list_enigmes?.at(partie[`Etape_equipe_${currentEquipe}`]?.length)?.id;
      // Check not undefined
      if (!!newId) {
        updatePartie.mutate([{
            id: partie?.id,
            [`Etape_equipe_${currentEquipe}`]: [...partie[`Etape_equipe_${currentEquipe}`], newId],
        }])
      }
    }
  }, [hasReached])

  const handleClose = () => setShow(false)

  const buttonAskForHelp = () => {
    mutationAskForHelp.mutate(partie?.id);
    setShow(true)
  }

  const isToCome = partie?.Statut === "A venir";
  const hasStarted = ( timer -  partie?.Scenario?.scenario?.data?.Temps_jeu * 60000 < 0) 

  return (isToCome && !hasStarted) ? (
    <div className="container">
      <div className='d-flex flex-column justify-content-center' style ={{ height:'100vh' }}>
          <div className="d-flex justify-content-center align-items-center"  >
              <div className="spinner-border " role="status">
                  <span className="sr-only">Loading...</span>
              </div>
              <div className="mt-3 ml-2">
                  <p> {text_language_dict[language]?.is_to_come} </p>
              </div>
          </div>
          <p className="d-block text-center mt-4">  {text_language_dict[language]?.message_pre_partie_1} {moment(partie?.Heure_debut, 'YYYY-MM-DDTHH:mm:ss.sssZ').format(text_language_dict[language]?.date_format)} {text_language_dict[language]?.message_pre_partie_2} {moment(partie?.Heure_debut, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('HH:mm')} </p>
      </div>
    </div>
  ) : (
    
      <div className='d-flex justify-content-center h-100' >
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11 h-100 d-flex flex-column justify-content-between px-3 pt-4" >
          <div>
            <h1 className='mb-0'> {text_language_dict[language]?.timer}</h1>
            <div className="text-center">
              { (!(timer) && !(timer === 0 )) 
                ? (<div className="spinner-border m-4" role="status"> </div>) 
                : (<h1 className="d-block  display-2 mb-3 mt-4">   {moment(timer).add(-60, 'minutes').format('HH:mm:ss')}  </h1>)
              }
              <div className="d-flex flex-column flex-direction" >
                { list_enigmes?.map((o, index) => (
                  <button 
                    key={index} 
                    type="button" 
                    onClick={() => openEnigme(o?.id)} 
                    className={"btn d-flex justify-content-between shadow-sm " + ((index > 0) ? 'mt-3 ' : ' ' ) + (( partie[`Etape_equipe_${currentEquipe}`]?.includes(o?.id) ) && !(moment(timer).format('HH:mm:ss') === '01:00:00') && (hasStarted)   ? 'btn-white' : 'btn-light text-muted' )} 
                    disabled={ !partie[`Etape_equipe_${currentEquipe}`]?.includes(o?.id) || (moment(timer)?.format('HH:mm:ss') === '01:00:00') || !(hasStarted)}
                    >
                      <span>Enigme {index + 1}</span>
                      <span>{(!partie[`Resolue_equipe_${currentEquipe}`]?.includes(o?.id)) ? '' : o?.Reponse}</span>
                  </button>
                ))
                }
              </div>
              <div className='d-flex flex-column'>
                {/* { ((timer === 0) && (partie[`Resolue_equipe_${currentEquipe}`]?.length < list_enigmes?.length)) 
                  ? (<video height="500" controls autoPlay muted style={styles} > <source src={`https://nissalentours-staging.s3.eu-west-1.amazonaws.com/brice_${(language === 'Anglais') ? 'english' : 'french'}.mp4?autoplay=1`} type="video/mp4"></source> </video>)
                  : (<span></span>)
                } */}
              </div>
            </div>

          </div>
          <div>
            <div className='d-flex justify-content-end pb-4'>
              <button onClick={() => buttonAskForHelp()} type="button" className="btn btn-light shadow-sm" >
                <i className="fa fa-life-ring fa-rotate-90 mr-2" style={{fontSize : '2.5vh', padding : '0.4vh', transform : 'rotate(90deg)'}}></i>
                <span>{text_language_dict[language]?.message_unlock}</span>
              </button>
            </div>
            <div className='d-flex justify-content-end pb-4'>
              <a href={'tel:' + partie?.Numero_urgence} type="button" className="btn btn-light shadow-sm"  >
                <i className="fa fa-phone fa-rotate-90 mr-2" style={{fontSize : '2.5vh', padding : '0.4vh', transform : 'rotate(90deg)'}}></i>
                <span>{text_language_dict[language]?.message_urgence}</span>
              </a>
            </div>
            <small className='d-block m-0 text-center pb-2'>{text_language_dict[language]?.copyright}</small>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>{messageNotification}</Modal.Body>
        </Modal>
      </div>
  )
}

export default Main;


const styles =  {
  position: "absolute",
  top: "calc(50% - 250px)",
  left: "0px",
  width: "100%"
}

